import { NgModule } from '@angular/core';

import { FeatherIconDirective } from '@core/directives/core-feather-icons/core-feather-icons';
import { RippleEffectDirective } from '@core/directives/core-ripple-effect/core-ripple-effect.directive';
import { DecimalsDirective } from '@core/directives/core-two-decimals/core-two-decimals.directive';

@NgModule({
  declarations: [RippleEffectDirective, FeatherIconDirective, DecimalsDirective],
  exports: [RippleEffectDirective, FeatherIconDirective, DecimalsDirective]
})
export class CoreDirectivesModule {}
